import React from "react";
import { graphql } from "gatsby";

import BodyParser from "./StrapiParser";
import { IStrapiProps } from "./StrapiParser/types";

import GenericHeroSection from "../sections/GenericHero/GenericHero";

// assets
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Layout from "./layout";

const ArticleTemplate = ({ data, pageContext }: any) => {
  const Article = data?.cms?.blogArticle?.data?.attributes;

  return (
    <main>
      <Layout>
        <div style={{ marginBottom: "20px" }}>
          <GenericHeroSection
            title={Article?.Article_Title}
            description={Article?.Article_Subtitle}
            image={Article?.Article_Thumbnail}
          />
          {Article?.Body &&
            Article?.Body.map((strapiComponent: IStrapiProps["component"]) => (
              <BodyParser key={strapiComponent?.id} component={strapiComponent} />
            ))}
        </div>
      </Layout>
    </main>
  );
};

export const pageQuery = graphql`
  query($id: ID!) {
    cms {
      blogArticle(id: $id) {
        data {
          attributes {
            Article_Subtitle
            Article_Title
            Content_Tag
            Article_Thumbnail {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            Body {
              ...ParagraphFeatureSection
              ...Paragraph
              ...ParagraphHighlight
              ...Subtitle
            }
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
